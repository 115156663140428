import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from 'api/mediaApi';
import { formatQueryParams } from "helpers/format";

export const getMedias = createAsyncThunk("Medias" , async (params: any = {}) => {
  try{
    const response = await mediaApi.medias(formatQueryParams(params))
    return response;
  }catch (error) {
    return error;
  }
});

// =========== Begin: Media ===========
export const getMedia = async (id: number) => {
  try{
    const response = await mediaApi.getMedia(id)
    return response;
  }catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try{
    const response = await mediaApi.postMedia(data)
    return response;
  }catch (error) {
    return error;
  }
};

export const putMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.putMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putAccountMedia = async (id:number, data: any = {}) => {
  try{
    const response = await mediaApi.putAccountMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllMedias =  async(params: any = {}) => {
  try{
    const response = await mediaApi.allMedias(params)
    return response;
  }catch (error) {
    return error;
  }
};
// =========== End: Media ===========

const ROLES_FOR_APP:any = {    
    DASHBOARD_VIEW: "view",
  
    REPORT_AGENCY: 'reports-agency'
};

const isHavePermissionRole = (keyRoles: string | string[], permissions: {id: string | number, name: string}[] = []):boolean => {
    const roles =  typeof keyRoles === 'string' ? [keyRoles] : keyRoles;
    return  true; // permissions?.some(item => roles?.includes(item.name));
};

export { ROLES_FOR_APP, isHavePermissionRole };
import { createSlice } from "@reduxjs/toolkit";
import { IReport } from "api/types/_agency";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { reportAgencies } from "./thunk";

export interface IState {
  agencies: ResponseData<IReport[]> & PaginationResponse | null,
  isAgencyLoading: boolean,
  isAgencySuccess: boolean,

  error: any,
};

export const initialState: IState = {
  agencies: null,
  isAgencyLoading: false,
  isAgencySuccess: false,

  error: {},
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //report agencies
    builder.addCase(reportAgencies.pending, (state: IState, action: any) => {
      state.isAgencyLoading = true
    });
    builder.addCase(reportAgencies.fulfilled, (state: IState, action: any) => {
      state.agencies = action.payload.data;
      state.isAgencySuccess = true;
      state.isAgencyLoading = false;
    });
    builder.addCase(reportAgencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAgencySuccess = false;
      state.isAgencyLoading = false;
    });
  },
});

export default ReportSlice.reducer;
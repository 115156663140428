import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IReport } from "./types/_agency";
import { ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/agency';
const agencyApi = {
  agencyReports(params: any = {}) : Promise<AxiosResponse<ResponseData<IReport>, any>>  {
    const url = `reports/agency`;
    return api.get(url, params)
  },
}
export default agencyApi

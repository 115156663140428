import { APIClient } from "./axiosClient"
const api = new APIClient();

const path = '/auth';
const authApi = {
  login(data: any) {
    const url = `${path}/login`
    return api.post(url, data)
  },
  refresh(data: any) {
    const url = `${path}/refresh`
    return api.post(url, data)
  },
  logout() {
    const url = `${path}/logout`
    return api.post(url, {})
  },
  permissions(params: any = {}) {
    const url = `/permissions/current`
    return api.get(url, params)
  },
  profile(params: any = {}) {
    const url = `/auth/profile`
    return api.get(url, params)
  },
}
export default authApi
